import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Tilty from 'react-tilty';


const BannerFour = () => {
    return (
        <div className="banner banner-style-4">
            <div className="container">
                <div className="banner-content">
                    <h1 className="title">Asegurando hoy, el derecho del mañana.</h1>
                    <p>Contacta con Hitch para descubrir cómo podemos
                        transformar tus necesidades legales en seguridad y
                        certeza para el futuro.</p>
                    <div>
                        <Link
                            smooth
                            to="/#contact"
                            className="axil-btn btn-fill-primary btn-large"
                        >
                            Contáctanos
                        </Link>
                    </div>
                </div>
                <div className="banner-thumbnail">
                    <div className="large-thumb">
                        <Tilty perspective={3000}>
                            <img src={process.env.PUBLIC_URL + "/images/hitch-imagotipo-color.png"} alt="Shape" />
                        </Tilty>
                    </div>
                </div>
                <div className="banner-social">
                <div className="border-line" />
                </div>
            </div>
            <ul className="list-unstyled shape-group-19">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerFour;