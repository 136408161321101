import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedin, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import FormTwo from '../../component/contact/FormTwo';


const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><Link to="https://www.facebook.com/notaria90"><FaFacebookF /></Link></li>
                            <li><Link to="https://x.com/90_legal"><FaXTwitter /></Link></li>
                            <li><Link to="https://www.linkedin.com/company/notaria-publica-90"><FaLinkedin /></Link></li>
                            <li><Link to="https://www.instagram.com/notaria90playa"><FaInstagram /></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="section section-padding">
                        <div id="contact" className="container">
                            <div className="row">
                                <div className="col-xl-5 col-lg-6">
                                    <div className="contact-form-box shadow-box mb--30">
                                        <h3 className="title">Escríbenos</h3>
                                        <FormTwo />
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-6 offset-xl-1">
                                    <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                        <h4 className="title">Teléfono</h4>
                                        <p>
                                            Horarios de atención
                                            Lunes a viernes de 09:00 a 18:00 hs
                                        </p>
                                        <h4 className="phone-number"><a href="tel:+529848733412">+52 (984) 873 3412</a></h4>
                                        <h4 className="phone-number"><a href="tel:+529848030555">+52 (984) 803 0555</a></h4>
                                        <br/>
                                        <h4 className="title">WhatsApp</h4>
                                        <h4 className="phone-number"><a href="https://api.whatsapp.com/send?phone=529841787636">+52 (984) 178 7636</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="list-unstyled shape-group-12">
                            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-13.png"} alt="Bubble" /></li>
                            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-15.png"} alt="Bubble" /></li>
                            <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by Hitch.</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    {/* <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;