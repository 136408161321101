import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerFour from '../component/banner/BannerFour';
import SectionTitle from '../elements/section-title/SectionTitle';
import Tilty from 'react-tilty';

const detailsService = {
    title: "Certificacion notarial en blockchain",
    description: "Certificacion notarial en blockchain",
    process: {
        sectionSub: "",
        sectionTitle: "Conócenos",
        paragraph: "En Hitch, nuestro compromiso con la sofisticación se materializa en una plataforma moderna, transformando el futuro de la certificación notarial y legal a través de la inmutabilidad y seguridad del blockchain.",
        steps: [
            {
                thumb: "/images/hitch/legal-blockchain-x.png",
                subtitle: "",
                title: "Reinventando la confianza legal",
                paragraph: "Revolucionamos la confianza legal, aplicando la innovación blockchain para garantizar una seguridad continua.Hitch redefine la fidelidad y precisión en el ámbito legal.",
            },
            {
                thumb: "/images/hitch/lady-justice.png",
                subtitle: "",
                title: "Desde la certificación de documentos hasta la gestión de bienes.",
                paragraph: "Nuestros servicios están diseñados para proporcionar una seguridad sin precedentes, asegurando la fidelidad inalterable y la precisión jurídica en cada transacción.",
            },
            {
                thumb: "/images/hitch-iso.png",
                subtitle: "",
                title: "El corazón de Hitch",
                paragraph: "Nuestra certificación digital brinda la validez legal y la tecnología blockchain es el núcleo de nuestra plataforma, garantizando la inmutabilidad y seguridad que nuestros clientes exigen. Es la base sobre la cual construimos un futuro legal más seguro y transparente.",
            }
        ]
    }
};

const HomeStartup = () => {
    return (
        <>
        <SEO title="Certificacion notarial en blockchain"/>
        <main className="main-wrapper">
        <HeaderOne />
        <BannerFour />
        <div className="section section-padding bg-color-light pb--70">
            <SectionTitle
                subtitle={detailsService.process.sectionSub}
                title={detailsService.process.sectionTitle}
                description={detailsService.process.paragraph}
                textAlignment=""
                textColor="mb--90"
            />
            <div className="container">
                {detailsService.process.steps.map((data, index) => (
                    <div key={index} className={`process-work ${(index % 2 === 1) ? "content-reverse" : ""}`}>
                        <Tilty perspective={2000}>
                            <div className="thumbnail">
                                <img src={process.env.PUBLIC_URL + data.thumb} alt="Thumbnail" />
                            </div>
                        </Tilty>
                        <div className="content">
                            <span className="subtitle">{data.subtitle}</span>
                            <h3 className="title">{data.title}</h3>
                            <p>{data.paragraph}</p>
                        </div>
                    </div>
                ))}
            </div>
            <ul className="shape-group-17 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-24.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-23.png"} alt="Bubble" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
                <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                <li className="shape shape-6"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
            </ul>
        </div>
        <FooterOne />
        </main>
        </>
    )
}

export default HomeStartup;

