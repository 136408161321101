import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Pronto te contactaremos, gracias.
        </Alert>
    );
};

const FormTwo = () => {
    // State variables for form inputs
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    // State variables for form submission status
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [result, showResult] = useState(false);

    const sendForm = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Prepare the data to be sent
        const data = {
            name,
            email,
            phone,
            message,
        };

        fetch(
            'https://u0a6suax90.execute-api.us-east-1.amazonaws.com/dev/hitch/contact',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Reset form fields
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');
                // Show success message
                showResult(true);
                // Hide success message after 5 seconds
                setTimeout(() => {
                    showResult(false);
                }, 5000);
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                // Handle error appropriately here
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <form onSubmit={sendForm} className="axil-contact-form">
            <div className="form-group">
                <label>Nombre</label>
                <input
                    type="text"
                    className="form-control"
                    name="contact-name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Email</label>
                <input
                    type="email"
                    className="form-control"
                    name="contact-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Teléfono</label>
                <input
                    type="tel"
                    className="form-control"
                    name="contact-phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                />
            </div>
            <div className="form-group mb--40">
                <label>¿Con qué te podemos ayudar?</label>
                <textarea
                    className="form-control"
                    name="contact-message"
                    rows="4"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>
            </div>
            <div className="form-group">
                <button
                    type="submit"
                    className="axil-btn btn-fill-primary btn-fluid btn-primary"
                    name="submit-btn"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Enviando...' : 'Enviar'}
                </button>
            </div>
            <div className="form-group">{result ? <Result /> : null}</div>
        </form>
    );
};

export default FormTwo;
