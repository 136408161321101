import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li><Link to="/#contact">Contacto</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;